<template>
  <footer class="footer">
    <button
      class="btn btn-primary text-semibold"
      v-if="showNextButton"
      @click="onClick"
      :disabled="disabled"
    >
      {{ labelNextButton }}
    </button>
  </footer>
</template>

<script>
import { EventBus } from "../services/events-bus";

export default {
  props: {
    showNextButton: Boolean,
    labelNextButton: String,
  },
  mounted() {
    EventBus.$on("can-next-changed", ({ canNext }) => {
      this.canNext = canNext;
    });
  },
  data() {
    return {
      canNext: false,
    };
  },
  methods: {
    onClick() {
      if (this.canNext) {
        EventBus.$emit("next");
      }
    },
  },
  computed: {
    disabled() {
      return this.canNext === false;
    },
  },
};
</script>


<style>
.footer {
  display: flex;
}

.footer .btn {
  flex: 1;
  margin: 20px;
}

@media only screen and (min-width: 768px) {
  .footer .btn {
    max-width: 975px;
   margin: 20px auto;
  }
}

</style>