<template>
  <div class="textbox">
    <input
      class="textbox-control text-regular"
      type="text"
      :placeholder="placeholder"
      :value="value"
      @input="onChange"
    />
    <label class="textbox-label text-small">{{ placeholder }}</label>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    value: String,
  },
  methods: {
    onChange($event) {
      this.$emit("input", $event.target.value);
    },
  },
};
</script>

<style>
.textbox {
  display: flex;
  margin-bottom: 20px;
  position: relative;
}
.textbox-label {
  display: none;
}
.textbox-control {
  flex: 1;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px var(--color-input-border);
  outline: none;
}
.textbox-control:focus {
  border: solid 2px var(--color-primary);
}
.textbox-control:focus + .textbox-label {
  display: inline-block;
  position: absolute;
  top: -12px;
  left: 16px;
  background: white;
  padding: 4px;
}
</style>