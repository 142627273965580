<template>
    <div class="scan step-container" v-if="isLoading === true">
      <div class="loading-wrapper">
        <Loading />
      </div>
    </div>
    <div class="scan step-container" v-else>
      <Error v-if="invalidQrCode === true">
        <span>{{ errorMessage }}</span>
        <span v-if="isiOS">Verwenden Sie ggf. eine niedrigere Auflösung des Fotos.</span>
      </Error>
      <Hint>
        Bitte übermitteln Sie uns im Folgenden Ihr Impfzertifikat als QR-Code
      </Hint>
      <div class="camera-wrapper" v-if="scanType === 'camera'">
        <qrcode-stream @decode="onQrCodeRecohnized"></qrcode-stream>
      </div>
      <div>
        <ArrowButton
          :image="Scan"
          label="QR-Code einscannen"
          @click.native="scanType = 'camera'"
        />
        <label class="arrow-button">
          <img :src="Upload" alt="icon-upload" />
          <span class="text-regular label">QR-Code hochladen</span>
          <img :src="Right" alt="arrow-right" />
          <input id="fileInput-scan" accept="image/*, application/pdf" type="file" @change="handleFileChange"/>
        </label>
      </div>
    </div>
</template>
 
<script>
import { unpack } from "../../services/unpack-covid-code";
import { QrcodeStream} from "vue-qrcode-reader";
import Error from "../UserControls/Error.vue";
import Hint from "../UserControls/Hint.vue";
import ArrowButton from "../UserControls/ArrowButton.vue";
import Scan from "../../assets/Scan.png";
import Upload from "../../assets/Upload.png";
import Right from "../../assets/Right.svg";
import Loading from "../Loading.vue";
import { EventBus } from "../../services/events-bus";
import isiOS from "is-ios";

export default {
  components: {
    QrcodeStream,
    Error,
    Hint,
    ArrowButton,
    Loading,
  },
  data() {
    return {
      invalidQrCode: false,
      scanType: "",
      Scan,
      Upload,
      Right,
      isLoading: false,
      isiOS,
      errorMessage: null
    };
  },
  methods: {
    async onQrCodeRecohnized(qrCode) {
      if (!qrCode.startsWith("HC1:")) {
        this.invalidQrCode = true;
        this.errorMessage = "Ihr QR-Code konnte nicht validiert werden. Bitte versuchen Sie es erneut."
        return;
      }
      const certificate = await unpack(qrCode);
      EventBus.$emit("scan-complete", { qrCode, certificate });
    },
    async handlePdf(file) {
      return fetch("/api/qr-code-scanner/pdf",
      {
        headers: { "content-type": file.type },
        method: "post",
        body: file,
      })

    },
    async handleImage(file) {
      return await fetch("/api/qr-code-scanner/image",
      {
        headers: { "content-type": file.type },
        method: "post",
        body: file,
      })
    },
    async handleFileChange(e) {
      this.isLoading = true;
      this.invalidQrCode = false;
      try {
        const file = e.target.files[0]
        if (file !== null) {
          let scanResult;
          if (file.type === "application/pdf") {
            scanResult = await this.handlePdf(file);
          }
          else {
            scanResult = await this.handleImage(file);
          } 
          if (!scanResult.ok) {
            throw new Error()
          }
          const qrCode = await scanResult.text()
          this.isLoading = false;
          this.onQrCodeRecohnized(qrCode);
        }
      }
      catch (e) {
        this.isLoading = false;
        this.invalidQrCode = true;
        this.errorMessage = "Ihr QR-Code konnte nicht validiert werden. Bitte versuchen Sie es erneut."
      }
    }
  }
};
</script>

<style>
.scan {
  gap: 5px;
  padding-bottom: 25px;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

@media only screen and (min-width: 768px) {
  .scan {
    text-align: center;
  }
  .arrow-button {
    width: 60%;
    margin: 0 auto;
    text-align: left;
  }
  .camera-wrapper {
    width: calc(60% + 30px);
    margin: 0 auto;
  }
}

.scan .hint,
.scan .error {
  margin: 20px 20px 15px 20px;
}

.arrow-button input[type="file"] {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

</style>