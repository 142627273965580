<template>
  <div class="voucherpayment step-container">
    <div class="top">
      <Error v-if="error !== null">
        {{ error }}
      </Error>
      <Hint>
        Geben Sie den Gutschein ein, den Sie für die Erstellung Ihres
        personalisierten Covid Passes erhalten haben.
      </Hint>
      <TextBox class="code-input" placeholder="Gutscheincode" v-model="code" />
    </div>
    <div class="bottom">
      <button
        class="btn btn-primary text-semibold"
        @click="checkout"
        :disabled="!isValidCode || isLoading"
      >
        Bestellung abschließen
      </button>
    </div>
  </div>
</template>

<script>
import TextBox from "../UserControls/TextBox.vue";
import Hint from "../UserControls/Hint.vue";
import Error from "../UserControls/Error.vue";
import { EventBus } from "../../services/events-bus";

export default {
  name: "VoucherPayment",
  components: {
    TextBox,
    Hint,
    Error,
  },
  props: {
    cart: Object,
  },
  data() {
    return {
      code: "",
      error: null,
      isLoading: false, 
    };
  },
  computed: {
    isValidCode() {
      return this.code && this.code.length > 8;
    }
  },
  mounted(){
    EventBus.$emit("set-current-order-id", {currentOrderContentItemId: undefined})
  },
  methods: {
    async checkout() {
      this.isLoading = true;
      const result = await fetch(
        `/api/payment/checkout/voucher/order/create/${this.code}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.cart),
        }
      );
      if (result.ok) {
        const orderData = await result.json();
        EventBus.$emit("set-current-order-id", {currentOrderContentItemId: orderData.contentItemId})
        EventBus.$emit("set-step", { stepName: "success" });
      } else {
        const error = await result.text();
        if (error == "VOUCHER_CODE_NOT_FOUND") {
          this.error = "Der eingegebene Gutscheincode ist ungültig";
        } else if (error == "VOUCHER_CODE_ALREADY_REDEEMED") {
          this.error = "Der eingegebene Gutscheincode wurde bereits eingelöst";
        } else {
          this.error = "Es ist etwas schief gegangen.";
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
.steps .step-container.voucherpayment {
  justify-content: space-between;
}

.voucherpayment .top,
.voucherpayment .bottom {
  display: flex;
  flex-flow: column;
  gap: 20px;
  padding: 20px;
}
</style>
