import zlib from "zlib";
import base45 from "base45";
import { decodeFirst } from "cbor-web";

export async function unpack (content) {
  try {
    const encodedBase45Buffer = content.substring(4);
    const decodedBase45Buffer = base45.decode(encodedBase45Buffer);
    const decodedBase45Buffer2 = await inflate(decodedBase45Buffer);
    const certifcate = await decodeCborStructure(decodedBase45Buffer2);
    return certifcate
  }
  catch (e) {
    console.log(e);
  }
}

function inflate(buffer) {
  return new Promise((resolve, reject) => {
    zlib.inflate(buffer, function (error, inflatedBuffer) {
        if (error) {
          reject(error);
        }
        else {
          resolve(inflatedBuffer)
        }
    })
  });
}

function decodeCborFirst(buffer) {
  return new Promise((resolve, reject) => {
    decodeFirst(buffer, function (error, obj) {
      if (error) {
        reject(error);
      }
      else {
        resolve(obj);
      }
    });
  });
}

const ClaimKeyHcert = -260
const ClaimKeyEuDgcV1 = 1

async function decodeCborStructure (buffer) {
  const innerObject1 = await decodeCborFirst(buffer)
  const cwt = innerObject1.toJSON().value[2]
  const innerObject2 = await decodeCborFirst(cwt)
  const eudgc = innerObject2.get(ClaimKeyHcert).get(ClaimKeyEuDgcV1);
  return eudgc
}
