<template>
  <div class="arrow-button">
    <img :src="image" alt="icon" />
    <span class="text-regular label">{{ label }}</span>
    <img :src="Right" alt="arrow-right" />
  </div>
</template>

<script>
import Right from "../../assets/Right.svg";
export default {
  props: {
    label: String,
    image: String,
  },
  data() {
    return {
      Right,
    };
  },
};
</script>

<style>
.arrow-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 16px;

  cursor: pointer;
  box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.1);
}

.arrow-button .label {
  flex: 1;
  color: var(--text-dark);
  margin-left: 7px;
}
</style>