<template>
  <div class="paypalpayment step-container">
    <Error v-if="error != null">{{ error }}</Error>

    <div id="paypal-button-container"></div>
  </div>
</template>

<script>
import { EventBus } from "../../services/events-bus";
import Error from "../UserControls/Error.vue";

export default {
  name: "PaypalPayment",
  props: {
    cart: Object,
  },
  components: {
    Error,
  },
  data() {
    return {
      contentItemId: null,
      error: null,
    };
  },
  methods: {
    createPaypalActions() {
      window.paypal
        .Buttons({
          createOrder: async () => {
            return fetch(
              `/api/payment/checkout/paypal/order/create/${this.contentItemId}`,
              {
                method: "post",
              }
            )
              .then(function (res) {
                return res.json();
              })
              .then(function (orderData) {
                return orderData.id;
              });
          },
          onApprove: async (data, actions) => {
            const res = await fetch(
              `/api/payment/checkout/paypal/order/capture/${data.orderID}`,
              {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.cart),
              }
            );
            const orderData = await res.json();
            // Three cases to handle:
            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //   (2) Other non-recoverable errors -> Show a failure message
            //   (3) Successful transaction -> Show confirmation or thank you
            // This example reads a v2/checkout/orders capture response, propagated from the server
            // You could use a different API or structure for your 'orderData'
            var errorDetail =
              Array.isArray(orderData.details) && orderData.details[0];
            if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
              return actions.restart(); // Recoverable state, per:
            } else if (errorDetail) {
              var msg = "Sorry, your transaction could not be processed.";
              if (errorDetail.description)
                msg += "\n\n" + errorDetail.description;
              if (orderData.debug_id) msg += " (" + orderData.debug_id + ")";
              this.error = msg;
            } else {
              // Successful capture! For demo purposes:
              EventBus.$emit("set-step", { stepName: "success" });
            }
          },
        })
        .render("#paypal-button-container");
    },
    paymentAuthorized(e) {
      this.state = "authorized";
      console.log(e);
    },
    paymentCompleted(e) {
      this.state = "completed";
      console.log(e);
    },
    paymentCancelled(e) {
      this.state = "cancelled";
      console.log(e);
    },
  },
  async mounted() {
    EventBus.$emit("set-current-order-id", {currentOrderContentItemId: undefined})
    const product = await fetch("/api/products/tln-corona-pass").then((res) => res.json());
    this.contentItemId = product.id;
    if (window.paypal) {
      this.createPaypalActions();
    }
  },
};
</script>

<style>
.steps .paypalpayment.step-container {
  padding: 20px;
}
#paypal-button-container {
  display: flex;
  justify-content: center;
}
</style>