<template>
  <header class="header">
    <main-menu :showBackButton="showBackButton" @back="onBack" />
    <div class="header-title-wrapper">
      <p class="header-subtitle">Ihr personalisierter Covid Pass</p>
      <p class="header-title">{{ title }}</p>
    </div>
  </header>
</template>

<script>
import MainMenu from "./MainMenu.vue";

export default {
  props: {
    showBackButton: Boolean,
    title: String,
  },
  components: {
    MainMenu,
  },
  methods: {
    onBack() {
      this.$emit("back");
    },
  },
};
</script>

<style>
.header {
  background: var(--color-primary);
  color: white;
  border-radius: 0 0 10px 10px;
  padding: 60px 20px 40px 20px;
}

@media only screen and (min-width: 768px) {
.header {
    border-radius: 0;
  }
}

.header-title-wrapper {
  margin-top: 20px;
  background: var(--color-primary);
  max-width: 975px;
  margin: 20px auto 0;
}

.header-subtitle {
  font-size: 12px;
  line-height: 19px;
}
.header-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}
</style>