<template>
  <div class="summary step-container">
    <Hint>Bitte überprüfen Sie Ihre Angaben.</Hint>
    <DeliveryResults :address="address" />
    <CodeResults :qrCode="qrCode" :certificate="certificate" />
  </div>
</template>

<script>
import CodeResults from "../UserControls/CodeResults.vue";
import DeliveryResults from "../UserControls/DeliveryResults.vue";
import Hint from "../UserControls/Hint.vue";

export default {
  components: {
    CodeResults,
    DeliveryResults,
    Hint,
  },
  props: {
    address: Object,
    certificate: Object,
    qrCode: String,
  },
};
</script>

<style>
.summary {
  padding: 20px;
  gap: 20px;
}
</style>