<template>
  <div class="code-summary step-container">
    <Hint>
      Bitte übermitteln Sie uns im Folgenden Ihr Impfzertifikat als QR-Code.
    </Hint>
    <CodeResults :qrCode="qrCode" :certificate="certificate" />
  </div>
</template>

<script>
import CodeResults from "../UserControls/CodeResults.vue";
import Hint from "../UserControls/Hint.vue";

export default {
  components: {
    CodeResults,
    Hint,
  },
  props: {
    certificate: Object,
    qrCode: String,
  },
};
</script>

<style>
.code-summary {
  padding: 20px;
  gap: 20px;
}
@media only screen and (min-width: 768px) {
  .code-summary {
    text-align: center;
  }
  .code-results {
    text-align: left;
  }
}

</style>