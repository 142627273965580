<template>
  <div class="steps">
    <Welcome v-if="stepName == 'welcome'" />
    <StaticPage
      v-else-if="stepName == 'static'"
      :href="staticHref"
    />
    <Scan v-else-if="stepName == 'scan'" />
    <CodeSummary
      v-else-if="stepName == 'codeResults'"
      :certificate="certificate"
      :qrCode="qrCode"
    />
    <Delivery v-else-if="stepName == 'delivery'" :address="address" />
    <Summary
      v-else-if="stepName == 'summary'"
      :certificate="certificate"
      :qrCode="qrCode"
      :address="address"
    />
    <Checkout v-else-if="stepName == 'checkout'" />
    <PaypalPayment v-else-if="stepName == 'paypalpayment'" :cart="cart" />
    <VoucherPayment v-else-if="stepName == 'voucherpayment'" :cart="cart" />
    <Success
      v-else-if="stepName == 'success'"
      :qrCode="qrCode"
      :certificate="certificate"
      :orderContentItemId="currentOrderContentItemId"
    />
  </div>
</template>

<script>
import Welcome from "./Steps/Welcome.vue";
import Scan from "./Steps/Scan.vue";
import CodeSummary from "./Steps/CodeSummary.vue";
import Delivery from "./Steps/Delivery.vue";
import Summary from "./Steps/Summary.vue";
import Checkout from "./Steps/Checkout.vue";
import PaypalPayment from "./Steps/PaypalPayment.vue";
import VoucherPayment from "./Steps/VoucherPayment.vue";
import StaticPage from "./Steps/StaticPage.vue";
import Success from "./Steps/Success.vue";
import { EventBus } from "../services/events-bus";

export default {
  name: "Steps",
  components: {
    Welcome,
    Scan,
    CodeSummary,
    Delivery,
    Summary,
    Checkout,
    PaypalPayment,
    VoucherPayment,
    Success,
    StaticPage,
  },
  props: {
    stepName: String,
  },
  data() {
    return {
      qrCode: "",
      certificate: {},
      address: {
        name: "",
        street: "",
        additional: "",
        zip: "",
        city: "",
        country: "Deutschland"
      },
      currentOrderContentItemId: undefined
    };
  },
  mounted() {
    EventBus.$on("scan-complete", this.onScanComplete);
    EventBus.$on("address-changed", this.onAddressChanged);
    EventBus.$on("set-current-order-id", this.onSetCurrentOrder);
    EventBus.$on("set-step", this.onSetStep);
  },
  computed: {
    previewSrc() {
      return (
        `/image?qrCodeContent=${encodeURIComponent(this.qrCode)}` +
        `&fullname=${this.certificate.nam.fn}%20${this.certificate.nam.gn}` +
        `&birthdate=${this.certificate.dob}&vaccinationDate=${this.certificate.v[0].dt}&vaccinationStatus=${this.certificate.v[0].dn}%20von%20${this.certificate.v[0].sd}`
      );
    },
    cart() {
      return {
        json: JSON.stringify({
          address: this.address,
          covPassCertificate: {
            birthDate: this.certificate.dob,
            vaccinationDate: this.certificate.v[0].dt,
            vaccinationStatus: `${this.certificate.v[0].dn} von ${this.certificate.v[0].sd}`,
            fullName: `${this.certificate.nam.fn} ${this.certificate.nam.gn}`,
            qrCode: this.qrCode,
          },
        }),
      };
    },
  },
  methods: {
    onScanComplete({ qrCode, certificate }) {
      this.qrCode = qrCode;
      this.certificate = certificate;
      EventBus.$emit("next");
    },
    onAddressChanged({ address }) {
      this.address = address;
    },
    onSetCurrentOrder({currentOrderContentItemId}) {
      this.currentOrderContentItemId = currentOrderContentItemId
    },
    onSetStep(step) {
      if (step.stepName === "static") {
        this.staticHref = step.href;
        this.$forceUpdate();
      }
    }
  },
};
</script>

<style>
.steps {
  flex: 1;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}

.steps .step-container {
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
}
</style>
