<template>
  <div class="delivery-results box">
    <p class="text-caption">Lieferadresse</p>
    <address class="text-secondary">
      {{ address.name }}<br />
      {{ address.street }}<br />
      {{ address.additional }}<br v-if="address.additional.length > 0" />
      {{ address.zip }}&nbsp;{{ address.city }}<br />
    </address>
  </div>
</template>

<script>
export default {
  props: {
    address: Object,
  },
};
</script>

<style>
.delivery-results {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>