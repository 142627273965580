<template>
  <div id="app">
    <Header :showBackButton="step.previous != null" :title="step.title" />
    <section class="content">
      <Steps :stepName="stepName" />
    </section>
    <Footer
      :showNextButton="step.showNextButton"
      :labelNextButton="step.labelNextButton"
    />
  </div>
</template>

<script>
import Steps from "./components/Steps.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import { EventBus } from "./services/events-bus";

export default {
  name: "App",
  components: {
    Steps,
    Header,
    Footer,
  },
  data() {
    return {
      stepName: "welcome",
      steps: {
        welcome: {
          previous: null,
          title: "TLN-Impfkarte",
          showNextButton: false,
          enableNextButton: false,
          labelNextButton: null,
          next: "scan",
        },
        scan: {
          previous: "welcome",
          title: "Nachweis",
          showNextButton: false,
          enableNextButton: false,
          labelNextButton: null,
          next: "codeResults",
        },
        codeResults: {
          previous: "scan",
          title: "Nachweis",
          showNextButton: true,
          enableNextButton: true,
          labelNextButton: "Weiter",
          next: "delivery",
        },
        delivery: {
          previous: "codeResults",
          title: "Lieferadresse",
          showNextButton: true,
          enableNextButton: false,
          labelNextButton: "Weiter",
          next: "summary",
        },
        static: {
          previous: "welcome",
          showNextButton: false,
          enableNextButton: false,
        },
        summary: {
          previous: "delivery",
          title: "Bestätigung",
          showNextButton: true,
          enableNextButton: true,
          labelNextButton: "Weiter zum Bezahlvorgang",
          next: "checkout",
        },
        checkout: {
          previous: "summary",
          title: "Bezahlvorgang",
          showNextButton: false,
          enableNextButton: false,
          labelNextButton: null,
          next: null,
        },
        paypalpayment: {
          previous: "checkout",
          title: "Paypal",
          showNextButton: false,
          enableNextButton: false,
          labelNextButton: null,
          next: null,
        },
        voucherpayment: {
          previous: "checkout",
          title: "Gutscheincode",
          showNextButton: false,
          enableNextButton: false,
          labelNextButton: null,
          next: null,
        },
        success: {
          previous: "scan",
          title: "Bestellabschluss",
          showNextButton: false,
          enableNextButton: false,
          labelNextButton: null,
          next: null,
        },
      },
    };
  },
  mounted() {
    EventBus.$on("next", this.onNext);
    EventBus.$on("back", this.onBack);
    EventBus.$on("set-step", this.onSetStep);
  },
  methods: {
    onBack() {
      this.stepName = this.step.previous;
    },
    onNext() {
      this.stepName = this.step.next;
    },
    onSetStep({ stepName, href, title }) {
      this.stepName = stepName
      console.log(stepName, href, title)
      if (stepName === "static") {
        this.steps[stepName].title = title;
        this.$forceUpdate();
      }
    },
  },
  watch: {
    step() {
      EventBus.$emit("can-next-changed", {
        canNext: this.step.enableNextButton,
      });
    },
  },
  computed: {
    step() {
      return this.steps[this.stepName];
    },
  },
};
</script>

<style>
:root {
  --color-primary: #17547f;
  --color-secondary: #7fa1b8;
  --color-error: #d94726;
  --color-success: #3aa478;
  --color-input-border: #999999;
  --text-grey: #808080;
  --text-dark: #292726;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

a {
  text-decoration: none;
}

address {
  font-style: initial;
}

p {
  margin: 0;
  padding: 0;
}

#app {
  font-family: "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  align-self: stretch;
  margin-left: auto;
  margin-right: auto;
}

.content > div {
  width: 100vw;
}

@media only screen and (min-width: 768px) {
  .content {
    box-shadow: 0 2px 19px 13px rgba(0, 0, 0, 0.1);
    transform: translateY(-25px);
    background-color: white;
    border-radius: 25px;
    align-items: flex-start;
    flex: 0;
    border: 0;
    margin-left: 25px;
    margin-right: 25px;
    max-width: 1025px;
    align-self: center;
  }
  .content > div {
    margin: 0 auto;
    min-height: 50vh;
    max-width: 65%;
  }
}

.btn {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
}
.btn.btn-primary {
  background: var(--color-primary);
  color: white;
  padding: 16px;
}
.btn.btn-primary[disabled="disabled"] {
  background: var(--color-secondary);
}

.box {
  padding: 15px;
  box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.text-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: var(--text-grey);
}

.text-secondary {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--text-dark);
}

.text-small {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.text-regular {
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
}

.text-semibold {
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
}

.text-bold {
  font-size: 17px;
  font-weight: 700;
  line-height: 25px;
}

.tint-white {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(213deg)
    brightness(103%) contrast(102%);
}
</style>
