<template>
  <div class="step-container welcome">
    <div class="welcome-flex-group">
      <div class="teaser-wrapper">
        <img :src="Teaser" alt="teaser" class="teaser" />
      </div>
      <div class="welcome-content">
        <p class="text-regular">
          Erhalten auch Sie Ihr digitales Impfzertifikat im Scheckkartenformat
          inkl. RFID Schutz und im individuellem Design.
        </p>
        <button class="btn btn-primary text-semibold" @click="onOrder">
          Jetzt TLN-Impfkarte bestellen
        </button>
      </div>
      <div class="welcome-content">
        <img :src="Gutschein" alt="gutschein-icon" />
        <p class="text-bold">Gutschein einlösen</p>
        <p class="text-regular">
          Sie haben einen Gutscheincode für eine TLN-Impfkarte erhalten? Lösen
          Sie Ihn während des Bezahlvorgangs ein!
        </p>
      </div>
      <div class="welcome-content">
        <img :src="Paypal" alt="paypal-icon" />
        <p class="text-bold">Kein Gutschein vorhanden?</p>
        <p class="text-regular">
          Auch ohne Gutscheincode können Sie Ihre personalisierte TLN-Impfkarte
          bestellen und ganz einfach per Paypal bezahlen.
        </p>
      </div>
    </div>
    <div class="welcome-footer">
      <Hint>Sie wollen TLN-Impfkarte in Ihrem eigenen Design anbieten?</Hint>
      <div class="welcome-footer-flex-group">
        <a
          target="_blank"
          class="arrow-button"
          href="mailto:impfkarte@tln.eu?subject=Anfrage%20Impfkarten%20in%20eigenem%20Design"
        >
          <img :src="Mail" alt="icon" />
          <span class="text-regular label">Kontakt per Mail</span>
          <img :src="Right" alt="arrow-right" />
        </a>
        <a class="arrow-button"
          target="_blank"
          href="tel:Nummer:+496131693010">
          <img :src="Phone" alt="icon" />
          <span class="text-regular label">Kontakt per Telefon</span>
          <img :src="Right" alt="arrow-right" />
        </a>
        <a
          class="arrow-button"
          href="https://www.tln-werbemittel.de/top-50/immunkarte-impfpass-als-karte.html?listtype=search&searchparam=impfkarte"
          target="_blank"
        >
          <img :src="Desktop" alt="icon" />
          <span class="text-regular label">Direkt bestellen</span>
          <img :src="Right" alt="arrow-right" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Teaser from "../../assets/Teaser.png";
import Paypal from "../../assets/Paypal.png";
import Gutschein from "../../assets/Gutschein.png";
import Mail from "../../assets/Mail.svg";
import Desktop from "../../assets/Desktop.svg";
import Right from "../../assets/Right.svg";
import Phone from "../../assets/Phone.svg";
import Hint from "../UserControls/Hint.vue";
import { EventBus } from "../../services/events-bus";

export default {
  components: {
    Hint,
  },
  data() {
    return {
      Teaser,
      Paypal,
      Gutschein,
      Mail,
      Desktop,
      Right,
      Phone
    };
  },
  methods: {
    onOrder() {
      EventBus.$emit("next");
    },
  },
};
</script>

<style>
.steps .step-container.welcome {
  margin: 40px 0 20px 0;
  gap: 40px;
}
@media only screen and (min-width: 768px) {
  .steps .step-container.welcome {
    margin: 40px auto 20px;
  }
}

.welcome-flex-group {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}
.teaser-wrapper {
  display: flex;
  justify-content: center;
}
.welcome-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 10px 20px;
  gap: 10px;
}
.welcome-content p {
  text-align: center;
}
.welcome-content > img {
  align-self: center;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .welcome {
    width: 95vw;
    max-width: 975px;
  }
  .welcome-flex-group {
    flex-wrap: wrap;
    flex-direction: row;
    padding: 20px;
    gap: 20px;
  }
  .welcome-content, .teaser-wrapper  {
    width: 45%;
    margin: 20px auto;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
}

.welcome-footer {
  display: flex;
  flex-flow: column;
  align-items: stretch;
}
.welcome-footer .hint {
  align-self: flex-start;
  margin: 20px 20px 10px 20px;
}
.welcome-footer-flex-group {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 10px;
  margin-top: 30px;
}

.welcome-footer-flex-group a img {
  max-height: 24px;
}

@media only screen and (min-width: 768px) {
  .welcome-footer {
    margin: 0 30px;
  }
  .welcome-footer-flex-group {
    flex-flow: row;
    gap: 10px;
    padding-bottom: 20px;
    flex-wrap: wrap;
  }
  .welcome-footer .hint {
    margin: 0;
  }
  .welcome-footer-flex-group a {
    width: calc(50% - 40px);
  }
}
</style>