<template>
  <div class="success step-container">
    <div class="success-message-container">
      <img class="success-icon" :src="Success" alt="success-icon" />
      <p class="success-message text-bold">
        Ihre Bestellung wurde erfolgreich abgeschlossen.
      </p>
    </div>
    <Loading v-if="!imagesLoaded" class="success-loading" />
    <div class="success-images">
      <div class="success-image-wrapper" :style="imageWrapperStyle">
        <img @load="onImageLoaded" class="success-image bottom" :src="backgroundSrc" />
        <img @load="onImageLoaded" class="success-image top" :src="previewSrc" />
      </div>
    </div>
  </div>
</template>

  },
  },
  computed: {
    imgSrc() {
      return `/image/qrCode?qrCodeContent=${encodeURIComponent(this.qrCode)}`;
    },
    wrapperStyle() {
      return this.imageLoaded ? "visibility:visible" : "visibility:hidden";
    }
  },

<script>
import Success from "../../assets/Success.png";
import Loading from "../Loading.vue"

export default {
  components: {
    Loading
  },
  props: {
    certificate: Object,
    qrCode: String,
    orderContentItemId: String
  },
  data() {
    return {
      Success,
      imagesLoaded: 0,
    };
  },
  methods: {
    onImageLoaded() {
      setTimeout(() => {
        this.imagesLoaded++;
      }, 200)
    }
  },
  computed: {
    previewSrc() {
      return (
        `/image?qrCodeContent=${encodeURIComponent(this.qrCode)}` +
        `&fullname=${this.certificate.nam.fn}%20${this.certificate.nam.gn}` +
        `&birthdate=${this.certificate.dob}&vaccinationDate=${this.certificate.v[0].dt}` +
        `&vaccinationStatus=${this.certificate.v[0].dn}%20von%20${this.certificate.v[0].sd}` +
        `&orderId=${this.orderContentItemId}`
      );
    },
    backgroundSrc() {
      return `/image/background?orderId=${this.orderContentItemId}`
    },
    imageWrapperStyle() {
      return this.imagesLoaded >= 2 ? "visibility:visible" : "visibility:hidden";
    }
  },
};
</script>

<style>
.success {
  margin: 0 auto;
  height: 75vh;
  max-width: 80%;
  text-align: center;
}

.steps .step-container.success {
  padding: 0 20px;
  align-items: center;
}

.success .success-message-container{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success .success-icon{
  margin-bottom: 10px;
}

.success .success-message {
  text-align: center;
  color: var(--color-success);
}

.success-image-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  padding-bottom: 30px;
}

.success-image {
  max-width: initial;
  width: 100%;
  border-radius: 10px;
  border-color: transparent;
}

.success-loading {
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  .success {
    min-height: 75vh;
    height: auto;
    text-align: center;
    max-width: 65%;
  }
  .success-image-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    left: -50%;
  }
}


</style>