<template>
  <div class="error">
    <img class="tint-white" :src="Close" alt="icon-error" />
    <p class="text-bold">
      <slot />
    </p>
  </div>
</template>

<script>
import Close from "../../assets/Close.svg";

export default {
  data() {
    return {
      Close,
    };
  },
};
</script>

<style>
.error {
  display: flex;
  flex-flow: row;
  align-items: center;
  background: var(--color-error);
  color: white;
  padding: 15px;
  border-radius: 4px;
}

.error img {
  margin-right: 14px;
}

.text-bold > span {
  margin-right: 4px;
}

</style>