<template>
  <div class="checkout step-container">
    <Hint> Wählen Sie Ihre bevorzugte Zahlungsmöglichkeit. </Hint>
    <div
      class="checkout-option"
      @click="onSelectCheckout({ checkouttype: 'voucherpayment' })"
    >
      <img :src="Gutschein" alt="icon-camera" />
      <span class="text-regular label">Gutscheincode eingeben</span>
      <img :src="Right" alt="arrow-right" />
    </div>
    <div
      v-if="paypalCheckoutAvailable"
      class="checkout-option"
      @click="onSelectCheckout({ checkouttype: 'paypalpayment' })"
    >
      <img :src="Paypal" alt="icon-upload" />
      <span class="text-regular label">Mit Paypal bezahlen</span>
      <img :src="Right" alt="arrow-right" />
    </div>
  </div>
</template>

<script>
import Hint from "../UserControls/Hint.vue";
import Gutschein from "../../assets/Gutschein.png";
import Paypal from "../../assets/Paypal.png";
import Right from "../../assets/Right.svg";
import { EventBus } from "../../services/events-bus";

export default {
  data() {
    return {
      Gutschein,
      Paypal,
      Right,
      paypalCheckoutAvailable: window.paypal !== undefined,
    };
  },
  components: {
    Hint,
  },
  methods: {
    onSelectCheckout({ checkouttype }) {
      EventBus.$emit("set-step", { stepName: checkouttype });
    },
  },
};
</script>

<style>
.checkout {
  gap: 5;
}

.checkout .hint {
  margin: 20px 20px 15px 20px;
}

@media only screen and (min-width: 768px) {
  .hint {
    text-align: center;
  }
}


.checkout-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 16px;

  cursor: pointer;
  box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.1);
}

.checkout-option .label {
  flex: 1;
  color: var(--text-dark);
  margin-left: 7px;
}
</style>