<template>
  <nav class="main-menu">
    <div class="menu-controls">
      <button class="btn menu-back" @click="back" v-if="showBackButton">
        <img
          class="tint-white"
          :src="Back"
          alt="icon-menu"
        />
      </button>
      <div class="btn menu-toggle" @click="toggle">
        <img class="tint-white" :src="Menu" alt="icon-menu" v-if="!isOpen" />
        <img class="tint-white" :src="Close" alt="icon-close" v-if="isOpen" />
      </div>
    </div>
    <div class="menu-items" :class="{ isOpen: isOpen }">
      <a class="menu-item text-regular" href="/agb" @click="openStatic" target="_blank">AGB</a>
      <a class="menu-item text-regular" href="/datenschutz"  @click="openStatic" target="_blank">
        Datenschutz
      </a>
      <a class="menu-item text-regular" href="/impressum"  @click="openStatic" target="_blank">
        Impressum
      </a>
    </div>
  </nav>
</template>

<script>
import Menu from "../assets/Menu.svg";
import Close from "../assets/Close.svg";
import Back from "../assets/Back.svg";
import { EventBus } from "../services/events-bus";

export default {
  props: {
    showBackButton: Boolean,
  },
  data() {
    return {
      Menu,
      Close,
      Back,
      isOpen: false,
    };
  },
  methods: {
    openStatic(e) {
      e.preventDefault();
      const href = e.target.attributes["href"].value;
      EventBus.$emit("set-step", { stepName: "static", href, title: e.target.innerText });
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    back() {
      EventBus.$emit("back");
    },
  },
};
</script>


<style>
.main-menu {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  max-width: 975px;
  margin: 0 auto;
  position: relative;
}
.menu-controls {
  flex: 1;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.menu-back {
  flex: 1;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  background: none;
  border: 0;
  position: absolute;
  top: -24px;
}
.menu-toggle {
  flex: 1;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
.menu-toggle img {
  width: 24px;
  height: 24px;
}

.menu-items {
  display: flex;
  flex-flow: column;
  max-height: 300px;
  transition: max-height 0.25s ease-in;
  overflow: hidden;
}

.menu-items:not(.isOpen) {
  max-height: 0;
  transition: max-height 0.15s ease-out;
}

.menu-items .menu-item {
  cursor: pointer;
  margin: 20px 0 20px 0;
  text-decoration: none;
  color: white;
}

@media only screen and (min-width: 768px) {
  .menu-items {
    display: flex;
    flex-flow: row;
    position: absolute;
    top: 0;
    right: 0;
  }
  .menu-items .menu-item {
    margin-left: 15px;
  }
  .menu-toggle {
    display: none;
  }
  .menu-items:not(.isOpen) {
    max-height: initial
  }
}
</style>