<template>
  <div class="static step-container">
    <p v-html="html"></p>
  </div>
</template>

<script>
export default {
  props: {
    href: String,
  },
  data() {
    return { html: null }
  },
  methods: {
    async fetchStaticPage(href) {
      const data = await fetch(href);
      const html = await data.text();
      this.html = html;
    }
  },
  watch: {
    async href() {
      await this.fetchStaticPage(this.href);
    }
  },
  async mounted() {
    await this.fetchStaticPage(this.href);
  }
};
</script>

<style>
.static h1 {
  display: none;
}

.static {
  margin: 0 auto;
  max-width: 90%;
}
</style>
