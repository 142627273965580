<template>
  <div class="delivery step-container">
    <TextBox
      placeholder="Vor- und Nachname"
      v-model="address.name"
      @input="onChange"
    />
    <TextBox
      placeholder="Straße und Hausnummer"
      v-model="address.street"
      @input="onChange"
    />
    <TextBox
      placeholder="Zuätzliche Angabe (Optional)"
      v-model="address.additional"
      @input="onChange"
    />
    <TextBox placeholder="PLZ" v-model="address.zip" @input="onChange" />
    <TextBox placeholder="Stadt" v-model="address.city" @input="onChange" />
    <div class="select">
      <select class="select-control" v-model="address.country">
        <option selected>Deutschland</option>
        <option>Österreich</option>
        <option>Luxemburg</option>
        <option>Frankreich</option>
        <option>Belgien</option>
        <option>Bulgarien</option>
        <option>Dänemark</option>
        <option>Estland</option>
        <option>Finnland</option>
        <option>Griechenland</option>
        <option>Irland</option>
        <option>Italien</option>
        <option>Kroatien</option>
        <option>Lettland</option>
        <option>Litauen</option>
        <option>Malta</option>
        <option>Niederlande</option>
        <option>Polen</option>
        <option>Portugal</option>
        <option>Rumänien</option>
        <option>Schweden</option>
        <option>Slowakei</option>
        <option>Slowenien</option>
        <option>Spanien</option>
        <option>Spanien</option>
        <option>Tschechische Republik</option>
        <option>Ungarn</option>
        <option>Zypern</option>
      </select>
      <label class="select-label text-small">Land</label>
    </div>
  </div>
</template>

<script>
import TextBox from "../UserControls/TextBox.vue";
import { EventBus } from "../../services/events-bus";
export default {
  props: {
    address: Object,
  },
  components: {
    TextBox,
  },
  mounted() {
    EventBus.$emit("can-next-changed", { canNext: this.isValid });
  },
  computed: {
    isValid() {
      return (
        this.address.name.length > 0 &&
        this.address.street.length > 0 &&
        this.address.zip.length > 0 &&
        this.address.city.length > 0
      );
    },
  },
  methods: {
    onChange() {
      EventBus.$emit("address-changed", {
        address: this.address,
      });
      EventBus.$emit("can-next-changed", { canNext: this.isValid });
    },
  },
};
</script>

<style>
.delivery {
  padding: 20px;
}
.select {
  display: flex;
  margin-bottom: 20px;
  position: relative;
}
.select-label {
  display: none;
}
.select-control {
  flex: 1;
  padding: 15px 10px;
  border-radius: 5px;
  border: solid 1px var(--color-input-border);
  outline: none;
  font-size: 17px;
}
.select-control:focus {
  border: solid 2px var(--color-primary);
}
.select-control:focus + .select-label {
  display: inline-block;
  position: absolute;
  top: -12px;
  left: 16px;
  background: white;
  padding: 4px;
}
</style>