<template>
  <div class="code-results box" :style="wrapperStyle">
    <div class="results-image-wrapper">
      <img :src="imgSrc" @load="onImageLoaded" />
    </div>
    <div class="results-data-wrapper">
      <div class="results-data-set">
        <p class="results-label text-regular">Name</p>
        <p class="results-data text-regular">
          {{ certificate.nam.fn }}&nbsp;{{ certificate.nam.gn }}
        </p>
      </div>
      <div class="results-data-set">
        <p class="results-label text-regular">Geburtsdatum</p>
        <p class="results-data text-regular">
          {{ certificate.dob }}
        </p>
      </div>
      <div class="results-data-set">
        <p class="results-label text-regular">Impfzertifikat</p>
        <p class="results-data text-regular">
          {{ certificate.v[0].dn }}
          &nbsp;von&nbsp;
          {{ certificate.v[0].sd }}
        </p>
        <p class="results-data text-regular">
          Geimpft am {{ certificate.v[0].dt }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    qrCode: String,
    certificate: Object,
  },
  data() {
    return {
      imageLoaded: false,
    }
  },
  methods: {
    onImageLoaded() {
      this.imageLoaded = true;
    }
  },
  computed: {
    imgSrc() {
      return `/image/qrCode?qrCodeContent=${encodeURIComponent(this.qrCode)}`;
    },
    wrapperStyle() {
      return this.imageLoaded ? "visibility:visible" : "visibility:hidden";
    }
  },
};
</script>

<style>
.code-results {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.results-image-wrapper {
  display: flex;
  margin-right: 15px;
}

.results-image-wrapper img {
  max-width: 100%;
  max-height: 300px;
}

.results-data-wrapper {
  flex: 1;
  display: flex;
  flex-flow: column;
  align-self: flex-start;
}

.results-data-set:nth-child(2) {
  margin: 15px 0 15px 0;
}

.results-label {
  font-size: 12px;
  color: var(--text-grey);
}
.results-data {
  font-size: 14px;
  color: var(--text-dark);
}
</style>