<template>
  <div class="lds-ring" :style="mainInlineStyle">
    <div :style="innerDivInlineStyle"></div>
    <div :style="innerDivInlineStyle"></div>  
    <div :style="innerDivInlineStyle"></div>
    <div :style="innerDivInlineStyle"></div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#808080"
    },
    size: {
      type: Number,
      default: 60,
    }
  },
  computed: {
    mainInlineStyle() {
      return `width: ${this.size}px; height: ${this.size}px`;
    },
    innerDivInlineStyle() {
      return `width: ${this.size * 0.8}px; height: ${this.size * 0.8}px; margin: ${this.size * 0.1}px; border: ${this.size * 0.1}px solid ${this.color}; border-color: ${this.color} transparent transparent transparent;`;
    },
  }
}
</script>

<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
